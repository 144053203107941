<template>
  <div>
    <div>
      <el-switch v-model="value.allWeek" :active-text="$t('settings.full')" :inactive-text="$t('settings.partial')" style="margin-left: 20px"></el-switch>
    </div>
    <div class="weekDaysSelector">
      <el-row>
        <el-col :span="4">
          <el-checkbox v-model="value.weekDays.monday" :disabled="value.allWeek" :label="$t('settings.monday')" class="weekDayCheckBox" />
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="value.weekDays.tuesday" :disabled="value.allWeek" :label="$t('settings.tuesday')" class="weekDayCheckBox" />
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="value.weekDays.wednesday" :disabled="value.allWeek" :label="$t('settings.wednesday')" class="weekDayCheckBox" />
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="value.weekDays.thursday" :disabled="value.allWeek" :label="$t('settings.thursday')" class="weekDayCheckBox" />
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="value.weekDays.friday" :disabled="value.allWeek" :label="$t('settings.friday')" class="weekDayCheckBox" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-checkbox v-model="value.weekDays.saturday" :disabled="value.allWeek" :label="$t('settings.saturday')" class="weekDayCheckBox" />
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="value.weekDays.sunday" :disabled="value.allWeek" :label="$t('settings.sunday')" class="weekDayCheckBox" />
        </el-col>
      </el-row>
    </div>
    <div class="timeSelector">
      <el-time-select
        v-model="value.startTime"
        :disabled="value.allWeek"
        :picker-options="{
          start: '00:00',
          step: '00:15',
          end: '23:45'
        }"
        :placeholder="$t('settings.selectStartTime')"
      >
      </el-time-select>
      <el-time-select
        v-model="value.endTime"
        :disabled="value.allWeek"
        :picker-options="{
          start: '00:00',
          step: '00:15',
          end: '23:45'
        }"
        style="margin-left: 20px"
        :placeholder="$t('settings.selectEndTime')"
      >
      </el-time-select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TimeTable',
  props: {
    value: {
      type: Object,
      default() { return {} }
    }
  },
  watch: {
    value(currentValue) {
      this.$emit('input', currentValue)
    }
  }
}
</script>

<style scoped>
.weekDaysSelector {
  padding-bottom: 20px;
}
</style>
